/* Gojs.css */
label {
  font: 300 1rem Roboto, helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.85);
  /* Grey 900 */
  display: block;
  padding: 2px;
}

p {
  font: 300 1rem Roboto, helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.85);
  /* Grey 900 */
}

/* Diagram.css */
.diagram-component {
  width: "100%";
  height: 400px;
  border: 1px solid black;
}

/* Inspector.css */
.inspector {
  font: 500 1rem Roboto, helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.85);
  /* Grey 900 */
  cursor: default;
  margin-top: 2px;
}

.inspector td,
th {
  padding: 2px;
}

.inspector input {
  font: 300 1rem Roboto, helvetica, sans-serif;
  border: 0;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  /* Grey 900 */
  padding: 2px;
}

.inspector input:disabled {
  background-color: #eeeeee;
  /* Grey 400 */
  color: #616161;
  /* Grey 700 */
  border-bottom: 1px solid #bdbdbd;
  /* Grey 900 */
}
